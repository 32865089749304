import { useBoolean } from '@chakra-ui/react';
import { getErrorMessage } from '@innerwell/utils';
import { useRouter } from 'next/navigation';

import useThemedToast from '@/hooks/useThemedToast';

import { webApiClient } from '@/api-client/apiClient';

import { EligibilityFormIntake } from '../Insurance/EligibilityFormIntake';
import BoxedModal from '../Modals/BoxedModal';
import { Card } from '../Cards/components/Card';
import { MyPatientResponseDto } from '@innerwell/dtos';
import { useInsuranceStatusText } from '../Insurance/use-insurance-status-text';
import { InsuranceOtherOptionsModal } from '../Insurance/InsuranceOtherOptionsModal';

const HomeCheckInsuranceEligibilityStep = ({
   patient,
   onCompleted,
}: {
   patient: MyPatientResponseDto;
   onCompleted?: (isEligible: boolean | null) => void;
}) => {
   const [
      isEligibilityModalOpen,
      { on: openEligibilityModal, off: closeEligibilityModal },
   ] = useBoolean();
   const [
      isOtherOptionsModalOpen,
      { on: openOtherOptionsModal, off: closeOtherOptionsModal },
   ] = useBoolean();

   const { push } = useRouter();
   const { toastError } = useThemedToast();

   const handleSwitch = async () => {
      try {
         const response = await webApiClient.programSwitch.getSwitchData();

         const info = response.body;
         if (!info.therapyPlan) {
            toastError('No selected therapy plan found');
            return;
         }

         push(
            `/purchase/plan/${info.switchCategoryId}?${new URLSearchParams({
               therapyPlan: info.therapyPlan,
               ref: '/',
            }).toString()}`,
         );
      } catch (e) {
         toastError(
            `Failed to get insurance program switch info: ${getErrorMessage(e)}`,
         );
      }
   };

   const insuranceStatusText = useInsuranceStatusText(patient);

   if (!insuranceStatusText || !patient.insurance) {
      return null;
   }

   const { heading, text, buttons } = insuranceStatusText.intakeProgram;

   return (
      <>
         <Card minH="none">
            <Card.Title fontWeight={600} lineHeight={1.2}>
               {heading}
            </Card.Title>
            <Card.Text pr={0}>{text}</Card.Text>

            <Card.ButtonGroup
               flexDir="row"
               gap={{ base: 2, lg: 3 }}
               flexWrap="wrap"
            >
               {buttons({
                  onSwitchClick: handleSwitch,
                  onCheckEligibilityClick: openEligibilityModal,
                  onOtherOptionsClick: openOtherOptionsModal,
               })}
            </Card.ButtonGroup>
         </Card>
         <InsuranceOtherOptionsModal
            onSwitchClick={handleSwitch}
            onCheckEligibilityClick={() => {
               openEligibilityModal();
               closeOtherOptionsModal();
            }}
            isOpen={isOtherOptionsModalOpen}
            onClose={closeOtherOptionsModal}
         />
         {isEligibilityModalOpen ? (
            <BoxedModal
               title="Insurance Eligibility"
               bg="background.secondary"
               isOpen={isEligibilityModalOpen}
               onClose={closeEligibilityModal}
            >
               <EligibilityFormIntake
                  patient={patient}
                  onCompleted={(isEligible) => {
                     closeEligibilityModal();

                     onCompleted?.(isEligible);
                  }}
               />
            </BoxedModal>
         ) : null}
      </>
   );
};

export default HomeCheckInsuranceEligibilityStep;
