import { Button, ModalProps } from '@chakra-ui/react';
import BoxedSandModal from '../Modals/BoxedSandModal';

interface InsuranceOtherOptionsModalProps extends Omit<ModalProps, 'children'> {
   onSwitchClick: () => void;
   onCheckEligibilityClick: () => void;
}

export const InsuranceOtherOptionsModal = ({
   onSwitchClick,
   onCheckEligibilityClick,
   ...rest
}: InsuranceOtherOptionsModalProps) => {
   return (
      <BoxedSandModal closeOnEsc closeOnOverlayClick {...rest}>
         <BoxedSandModal.Heading mt={4}>
            What would you like to do?
         </BoxedSandModal.Heading>

         <BoxedSandModal.ButtonGroup>
            <Button size="sm" onClick={onCheckEligibilityClick}>
               Check Eligibility
            </Button>
            <Button size="sm" variant="outline" onClick={onSwitchClick}>
               Continue without insurance
            </Button>
         </BoxedSandModal.ButtonGroup>
      </BoxedSandModal>
   );
};
