import { Card } from './components/Card';
import { CustomerIOEvents, MyPatientResponseDto } from '@innerwell/dtos';
import { usePatientProgram } from '@/contexts/patient-program-context';
import BoxedModal from '../Modals/BoxedModal';
import { Button, Flex, Text, useBoolean } from '@chakra-ui/react';
import { EligibilityFormIntake } from '../Insurance/EligibilityFormIntake';
import { FlexProps } from '@chakra-ui/layout';
import { useInsuranceStatusText } from '../Insurance/use-insurance-status-text';
import { InsuranceOtherOptionsModal } from '../Insurance/InsuranceOtherOptionsModal';
import { useCallback } from 'react';
import BoxedSandModal from '../Modals/BoxedSandModal';
import { webApiClient } from '@/api-client/apiClient';
import { useSession } from '@/contexts/session-context';
import { getAppSource } from '@/utils/analytics-utils';

export const InsuranceStatusCard = ({
   patient,
   onCompletedEligibilityCheck,
   ...rest
}: {
   patient: MyPatientResponseDto;
   onCompletedEligibilityCheck?: (isEligible: boolean | null) => void;
} & FlexProps) => {
   const { data: session } = useSession();
   const {
      programPhase: { phase },
   } = usePatientProgram();

   const [
      isEligibilityModalOpen,
      { on: openEligibilityModal, off: closeEligibilityModal },
   ] = useBoolean();

   const [
      isOtherOptionsModalOpen,
      { on: openOtherOptionsModal, off: closeOtherOptionsModal },
   ] = useBoolean();

   const [isSwitchModalOpen, { on: openSwitchModal, off: closeSwitchModal }] =
      useBoolean();

   const onSwitchClick = useCallback(() => {
      if (!session) {
         return;
      }

      webApiClient.customerio.track({
         body: {
            id: session['cognito:username'],
            name: CustomerIOEvents.SwitchInsuranceToCash,
            app_source: getAppSource(),
         },
      });
      closeOtherOptionsModal();
      openSwitchModal();
   }, [closeOtherOptionsModal, openSwitchModal, session]);

   const insuranceStatusText = useInsuranceStatusText(patient);

   if (!insuranceStatusText || !phase || !session) {
      return null;
   }

   const { heading, text, buttons } = insuranceStatusText.otherProgram;

   return (
      <Flex flexDir="column" gap={4} {...rest}>
         <Card minH="none" bg="background.secondary" maxW="none" p={0}>
            <Card.Title fontWeight={600}>{heading}</Card.Title>
            <Card.Text pr={0}>{text}</Card.Text>
            <Card.ButtonGroup
               flexDir="row"
               gap={{ base: 2, lg: 3 }}
               flexWrap="wrap"
            >
               {buttons({
                  onCheckEligibilityClick: openEligibilityModal,
                  onOtherOptionsClick: openOtherOptionsModal,
                  onSwitchClick,
               })}
            </Card.ButtonGroup>
         </Card>

         <BoxedSandModal
            isOpen={isSwitchModalOpen}
            onClose={closeSwitchModal}
            closeOnEsc
            closeOnOverlayClick
         >
            <BoxedSandModal.Heading
               mb={4}
            >{`We're on it`}</BoxedSandModal.Heading>
            <Text>
               Our Member Support Team will be in touch within one business day
               about next steps.
            </Text>
            <BoxedSandModal.ButtonGroup>
               <Button size="sm" onClick={closeSwitchModal}>
                  Close
               </Button>
            </BoxedSandModal.ButtonGroup>
         </BoxedSandModal>

         <InsuranceOtherOptionsModal
            onSwitchClick={onSwitchClick}
            onCheckEligibilityClick={() => {
               openEligibilityModal();
               closeOtherOptionsModal();
            }}
            isOpen={isOtherOptionsModalOpen}
            onClose={closeOtherOptionsModal}
         />

         <BoxedModal
            title="Insurance Eligibility"
            bg="background.secondary"
            isOpen={isEligibilityModalOpen}
            onClose={closeEligibilityModal}
         >
            <EligibilityFormIntake
               patient={patient}
               onCompleted={(isEligible) => {
                  closeEligibilityModal();

                  onCompletedEligibilityCheck?.(isEligible);
               }}
            />
         </BoxedModal>
      </Flex>
   );
};
