import {
   CustomerIOEvents,
   type MyPatientResponseDto,
   type StartSoharVerificationDto,
   startSoharVerificationSchema,
} from '@innerwell/dtos';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import useThemedToast from '@/hooks/useThemedToast';

import { webApiClient } from '@/api-client/apiClient';

import { getErrorMessage } from '@innerwell/utils';

import { Button, Divider, Flex, Input } from '@chakra-ui/react';
import { Select, type SingleValue } from 'chakra-react-select';
import { Controller } from 'react-hook-form';

import useInsurances from '@/hooks/react-query/useInsurances';
import { useZodForm } from '@/hooks/useZodForm';

import CustomFormControl from '../Devkit/CustomFormControl/CustomFormControl';
import { withMask } from 'use-mask-input';
import { chakraSelectStyles, type OptionString } from '@/utils/chakra-select';
import { getStateFromTitle } from '@innerwell/us-states';
import { ProcessingInsuranceModal } from './ProcessingInsuranceModal';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useEligibilityVerificationStatus } from '@/hooks/react-query/useEligibilityVerificationStatus';
import { queryKeys } from '@/types/query-keys';
import { NextLink } from '../NextLink/NextLink';
import { useSession } from '@/contexts/session-context';
import CustomCheckbox from '../Devkit/CustomFormControl/CustomCheckbox/CustomCheckbox';
import { getAppSource } from '@/utils/analytics-utils';
import { DateTime } from 'luxon';

export const EligibilityFormIntake = ({
   patient,
   onCompleted,
}: {
   patient: MyPatientResponseDto;
   onCompleted?: (eligible: boolean | null) => void;
}) => {
   const { data: session } = useSession();
   const sessionUsername = session?.['cognito:username'];
   const onCompletedRef = useRef(onCompleted);
   const { toastError, toastSuccess } = useThemedToast();
   const [isTimerActive, setIsTimerActive] = useState(false);
   const queryClient = useQueryClient();

   const form = useZodForm({
      schema: startSoharVerificationSchema,
      defaultValues: {
         firstName: patient.firstName,
         lastName: patient.lastName,
         dateOfBirth: patient.birthDate
            ? DateTime.fromISO(patient.birthDate).toFormat('MM/dd/yyyy')
            : '',
         subscriber: {
            isSameAsPatient: true,
            firstName: '',
            lastName: '',
         },
      },
   });

   const {
      register,
      handleSubmit,
      control,
      getValues,
      formState: { errors },
      watch,
   } = form;

   const isSubscriberSameAsPatient = watch('subscriber.isSameAsPatient');

   const state = patient?.state;

   const { insurances } = useInsurances({
      ...(typeof state === 'string'
         ? { state: getStateFromTitle(state)?.abbr ?? '' }
         : { state: '' }),
   });

   const insuranceOptions = useMemo(
      () =>
         insurances.map((insurance) => {
            return {
               label: insurance.name,
               value: insurance.payerId,
            };
         }),
      [insurances],
   );

   const {
      mutate: startEligibilityVerification,
      isPending: isSendingVerification,
      isSuccess,
   } = useMutation({
      mutationFn: async (data: StartSoharVerificationDto) => {
         setIsTimerActive(true);

         const response =
            await webApiClient.insurance.startEligibilityVerification({
               body: data,
            });

         return response.body;
      },
      onSuccess: () => {
         queryClient.resetQueries({
            queryKey: queryKeys.eligibilityVerificationStatus,
         });
         setInterval(() => {
            setIsTimerActive(false);
         }, 60 * 1000);
      },
      onError: (error) => {
         const errorMessage = getErrorMessage(error);

         toastError(errorMessage);
      },
   });

   const { data } = useEligibilityVerificationStatus();

   useEffect(() => {
      if (!onCompletedRef.current || !isSuccess) {
         return;
      }

      const { firstName, lastName, memberId, payerId } = getValues();

      if (data) {
         const {
            firstName: existingFirstName,
            lastName: existingLastName,
            memberId: existingMemberId,
            payerId: existingPayerId,
         } = data.requestData;

         if (
            firstName !== existingFirstName ||
            lastName !== existingLastName ||
            memberId !== existingMemberId ||
            payerId !== existingPayerId
         ) {
            return;
         }
      }

      if (typeof data?.isEligible === 'boolean') {
         onCompletedRef.current(data.isEligible);

         if (data.isEligible) {
            toastSuccess('You are eligible for insurance!');
         } else {
            toastError(
               <>
                  Sorry we were unable to verify your insurance. Please choose
                  to continue without insurance, or contact{' '}
                  <NextLink href="mailto:care@helloinnerwell.com">
                     care@helloinnerwell.com
                  </NextLink>{' '}
                  if you need further assistance.
               </>,
            );
         }

         queryClient.refetchQueries({ queryKey: queryKeys.programs });
         return;
      }

      if (!isTimerActive && !!data) {
         onCompletedRef.current(null);

         toastError('Unable to verify your insurance.');

         if (sessionUsername) {
            webApiClient.customerio.track({
               body: {
                  id: sessionUsername,
                  name: CustomerIOEvents.EligibilityCheckWaiting,
                  app_source: getAppSource(),
               },
            });
         }
      }
   }, [
      data,
      getValues,
      isSuccess,
      isTimerActive,
      queryClient,
      toastError,
      toastSuccess,
      sessionUsername,
   ]);

   return (
      <>
         {isTimerActive ? <ProcessingInsuranceModal /> : null}
         <form
            id="eligibility-form"
            onSubmit={handleSubmit((data) => {
               startEligibilityVerification(data);
            })}
         >
            <CustomFormControl
               mode="dark"
               name="roles"
               label="Insurance company"
               isInvalid={!!errors.payerId}
               errorMsg={errors.payerId?.message}
            >
               <Controller
                  name="payerId"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => {
                     return (
                        <Select
                           id="insuranceEncounters"
                           size="lg"
                           ref={ref}
                           maxMenuHeight={200}
                           chakraStyles={chakraSelectStyles}
                           options={insuranceOptions}
                           placeholder="Select your insurance"
                           value={insuranceOptions.find(
                              (opt) => opt?.value === value,
                           )}
                           onChange={(newValue) => {
                              if (!Array.isArray(newValue) && newValue) {
                                 const singleValue =
                                    newValue as SingleValue<OptionString>;
                                 if (singleValue) {
                                    onChange(singleValue?.value);
                                 }
                              } else {
                                 onChange('');
                              }
                           }}
                           filterOption={(
                              option: OptionString,
                              inputValue: string,
                           ) => {
                              return (
                                 option?.label
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase()) ?? false
                              );
                           }}
                           useBasicStyles
                        />
                     );
                  }}
               />
            </CustomFormControl>

            <Divider borderColor="background.primary" opacity={0.15} my={3} />

            <Flex gap={{ lg: 5 }} flexDir={{ base: 'column', lg: 'row' }}>
               <CustomFormControl
                  mode="dark"
                  label="Patient legal first name"
                  name="insurance-first-name"
                  isInvalid={!!errors.firstName}
                  errorMsg={
                     errors.firstName ? errors.firstName.message : undefined
                  }
               >
                  <Input
                     id="insurance-first-name"
                     placeholder="Patient legal first name"
                     bg="white"
                     {...register('firstName')}
                  />
               </CustomFormControl>

               <CustomFormControl
                  mode="dark"
                  label="Patient legal last name"
                  name="insurance-last-name"
                  isInvalid={!!errors.lastName}
                  errorMsg={
                     errors.lastName ? errors.lastName.message : undefined
                  }
               >
                  <Input
                     bg="white"
                     id="insurance-last-name"
                     placeholder="Patient legal last name"
                     {...register('lastName')}
                  />
               </CustomFormControl>
            </Flex>

            <Flex gap={{ lg: 5 }} flexDir={{ base: 'column', lg: 'row' }}>
               <CustomFormControl
                  mode="dark"
                  label="Date of birth"
                  name="insurance-dob"
                  isInvalid={!!errors.dateOfBirth}
                  errorMsg={
                     errors.dateOfBirth ? errors.dateOfBirth.message : undefined
                  }
                  helperText="(mm/dd/yyyy)"
               >
                  <Controller
                     control={control}
                     name="dateOfBirth"
                     render={({ field: { onChange, onBlur, value = '' } }) => {
                        return (
                           <Input
                              ref={withMask('99/99/9999')}
                              bg="white"
                              type="tel"
                              id="date-of-birth"
                              placeholder="Enter your date of birth"
                              onChange={onChange}
                              value={value}
                              onBlur={onBlur}
                           />
                        );
                     }}
                  />
               </CustomFormControl>

               <CustomFormControl
                  mode="dark"
                  label="Member ID"
                  tooltipText={`Your Member ID is located on the front of your
                     insurance card. Look for where it says "ID" and use the
                     number shown to the right.`}
                  name="insurance-member-id"
                  isInvalid={!!errors.memberId}
                  errorMsg={
                     errors.memberId ? errors.memberId.message : undefined
                  }
               >
                  <Input
                     bg="white"
                     id="insurance-member-id"
                     {...register('memberId')}
                     placeholder="Member ID"
                  />
               </CustomFormControl>
            </Flex>

            <CustomFormControl name="isSubscriberSameAsPatient" py={3}>
               <Controller
                  name="subscriber.isSameAsPatient"
                  control={control}
                  render={({ field: { onChange, value, ref } }) => {
                     return (
                        <CustomCheckbox
                           ref={ref}
                           variant="dark"
                           checked={String(value) === 'true'}
                           isChecked={String(value) === 'true'}
                           value={String(value)}
                           onChange={onChange}
                        >
                           Patient is the primary subscriber
                        </CustomCheckbox>
                     );
                  }}
               />
            </CustomFormControl>

            {!isSubscriberSameAsPatient && (
               <Flex gap={{ lg: 5 }} flexDir={{ base: 'column', lg: 'row' }}>
                  <CustomFormControl
                     mode="dark"
                     label="Primary subscriber first name"
                     name="primary-subscriber-first-name"
                     isInvalid={!!errors.subscriber?.firstName}
                     errorMsg={
                        errors.subscriber?.firstName
                           ? errors.subscriber?.firstName.message
                           : undefined
                     }
                  >
                     <Input
                        id="primary-subscriber-first-name"
                        placeholder="Primary subscriber first name"
                        bg="white"
                        {...register('subscriber.firstName')}
                     />
                  </CustomFormControl>

                  <CustomFormControl
                     mode="dark"
                     label="Primary subscriber last name"
                     name="primary-subscriber-last-name"
                     isInvalid={!!errors.subscriber?.lastName}
                     errorMsg={
                        errors.subscriber?.lastName
                           ? errors.subscriber?.lastName.message
                           : undefined
                     }
                  >
                     <Input
                        bg="white"
                        id="primary-subscriber-last-name"
                        placeholder="Primary subscriber last name"
                        {...register('subscriber.lastName')}
                     />
                  </CustomFormControl>
               </Flex>
            )}

            <Flex justifyContent="flex-end" pt={5}>
               <Button type="submit" isDisabled={isSendingVerification}>
                  Check
               </Button>
            </Flex>
         </form>
      </>
   );
};
