import {
   InsuranceError,
   InsuranceInfoStatus,
   MyPatientResponseDto,
} from '@innerwell/dtos';
import { ReactNode } from 'react';
import { Icon, IconName, IconProps } from '../Icon';
import { Card } from '../Cards/components/Card';

type CardButtonsClickEvents = {
   onSwitchClick?: () => void;
   onCheckEligibilityClick?: () => void;
   onOtherOptionsClick?: () => void;
};

const getIconColor = (iconName: IconName) => {
   if (iconName === 'insurance-ineligible') {
      return 'accent.danger';
   }

   if (iconName === 'insurance-error') {
      return 'accent.orange';
   }

   return 'accent.blue';
};

type CardProps = {
   heading: ReactNode;
   text: ReactNode;
   buttons: (props: CardButtonsClickEvents) => ReactNode;
};

const HeadingIcon = (props: IconProps) => {
   return (
      <Icon
         boxSize={5}
         display="inline-block"
         position="relative"
         top={0.5}
         left={2}
         {...props}
      />
   );
};

export const useInsuranceStatusText = (
   patient: MyPatientResponseDto,
): {
   intakeProgram: CardProps;
   otherProgram: CardProps;
} | null => {
   if (!patient.insurance) {
      return null;
   }

   const { status, error } = patient.insurance;

   if (status === InsuranceInfoStatus.NotStarted) {
      return {
         intakeProgram: {
            heading: <>Confirm your insurance coverage</>,
            text: (
               <>
                  Please give us the details of your insurance coverage so that
                  we can confirm your eligibility and verify that you are
                  covered.
               </>
            ),
            buttons: ({ onCheckEligibilityClick }) => (
               <Card.Button onClick={onCheckEligibilityClick}>
                  Check Eligibility
               </Card.Button>
            ),
         },
         otherProgram: {
            heading: <>Confirm your insurance coverage</>,
            text: (
               <>
                  Please give us the details of your insurance coverage so that
                  we can confirm your eligibility and verify that you are
                  covered.
               </>
            ),
            buttons: ({ onCheckEligibilityClick }) => (
               <Card.Button onClick={onCheckEligibilityClick}>
                  Check Eligibility
               </Card.Button>
            ),
         },
      };
   }

   if (
      status === InsuranceInfoStatus.Pending ||
      status === InsuranceInfoStatus.PendingError
   ) {
      return {
         intakeProgram: {
            heading: (
               <>
                  {`We're reviewing your insurance details`}
                  <HeadingIcon
                     name="insurance-pending"
                     color={getIconColor('insurance-pending')}
                  />
               </>
            ),
            text: (
               <>
                  {`We weren’t able to verify your insurance automatically, so
                  we’ve sent your information to our team for manual review.
                  This typically takes 1 business day. We’ll follow up as soon
                  as we have an update.`}
               </>
            ),
            buttons: ({ onSwitchClick }) => (
               <>
                  <Card.ButtonLink href="/support">
                     Contact Member Support
                  </Card.ButtonLink>
                  <Card.Button variant="outline" onClick={onSwitchClick}>
                     Continue without insurance
                  </Card.Button>
               </>
            ),
         },
         otherProgram: {
            heading: (
               <>
                  {`We're reviewing your insurance details`}
                  <HeadingIcon
                     name="insurance-pending"
                     color={getIconColor('insurance-pending')}
                  />
               </>
            ),
            text: (
               <>
                  {`We weren’t able to verify your insurance automatically, so
                  we’ve sent your information to our team for manual review.
                  This typically takes 1 business day. We’ll follow up as soon
                  as we have an update.`}
               </>
            ),
            buttons: () => (
               <Card.ButtonLink href="/support">
                  Contact Member Support
               </Card.ButtonLink>
            ),
         },
      };
   }

   if (status === InsuranceInfoStatus.Ineligible) {
      return {
         intakeProgram: {
            heading: (
               <>
                  {`This service isn't included in your current coverage`}
                  <HeadingIcon
                     name="insurance-ineligible"
                     color={getIconColor('insurance-ineligible')}
                  />
               </>
            ),
            text: (
               <>
                  {`It looks like your insurance doesn't currently cover this
                  service. If you think we've made a mistake, please contact
                  Member Support with a photo of the front and back of your
                  insurance card, and we'll gladly take another look.`}
               </>
            ),
            buttons: ({ onOtherOptionsClick }) => (
               <>
                  <Card.ButtonLink href="/support">
                     Contact Member Support
                  </Card.ButtonLink>
                  <Card.Button variant="outline" onClick={onOtherOptionsClick}>
                     Other options
                  </Card.Button>
               </>
            ),
         },
         otherProgram: {
            heading: (
               <>
                  {`This service isn't included in your current coverage`}
                  <HeadingIcon
                     name="insurance-ineligible"
                     color={getIconColor('insurance-ineligible')}
                  />
               </>
            ),
            text: (
               <>
                  {`It looks like your insurance no longer covers this service. If
                  you think we've made a mistake, please contact Member Support
                  with a photo of the front and back of your insurance card, and
                  we'll gladly take another look.`}
               </>
            ),
            buttons: ({ onOtherOptionsClick }) => (
               <>
                  <Card.ButtonLink href="/support">
                     Contact Member Support
                  </Card.ButtonLink>
                  <Card.Button variant="outline" onClick={onOtherOptionsClick}>
                     Other options
                  </Card.Button>
               </>
            ),
         },
      };
   }

   if (status === InsuranceInfoStatus.Error && error) {
      if (
         [
            InsuranceError.MemberId,
            InsuranceError.MemberDob,
            InsuranceError.MemberName,
         ].includes(error)
      ) {
         return {
            intakeProgram: {
               heading: (
                  <>
                     {`We couldn't match your insurance details`}
                     <HeadingIcon
                        name="insurance-error"
                        color={getIconColor('insurance-error')}
                     />
                  </>
               ),
               text: (
                  <>
                     {`Some of the information you entered doesn’t match what your
                     provider has on file. Please double-check your Member ID,
                     name, and date of birth, and re-enter them exactly as they
                     appear on your insurance card.`}
                     <br />
                     <br />
                     {`If you think we've made a mistake, please contact Member
                     Support with a photo of the front and back of your
                     insurance card, and we'll gladly take another look.`}
                  </>
               ),
               buttons: ({ onOtherOptionsClick }) => (
                  <>
                     <Card.ButtonLink href="/support">
                        Contact Member Support
                     </Card.ButtonLink>
                     <Card.Button
                        variant="outline"
                        onClick={onOtherOptionsClick}
                     >
                        Other options
                     </Card.Button>
                  </>
               ),
            },
            otherProgram: {
               heading: (
                  <>
                     {`We couldn't match your insurance details`}
                     <HeadingIcon
                        name="insurance-error"
                        color={getIconColor('insurance-error')}
                     />
                  </>
               ),
               text: (
                  <>
                     {`Some of the information you entered doesn’t match what your
                     provider has on file. Please double-check your Member ID,
                     name, and date of birth, and re-enter them exactly as they
                     appear on your insurance card.`}
                     <br />
                     <br />
                     {`If you think we've made a mistake, please contact Member
                     Support with a photo of the front and back of your
                     insurance card, and we'll gladly take another look.`}
                  </>
               ),
               buttons: ({ onOtherOptionsClick }) => (
                  <>
                     <Card.ButtonLink href="/support">
                        Contact Member Support
                     </Card.ButtonLink>
                     <Card.Button
                        variant="outline"
                        onClick={onOtherOptionsClick}
                     >
                        Other options
                     </Card.Button>
                  </>
               ),
            },
         };
      }

      // InsuranceError.DiscrepancyClinician,
      // InsuranceError.DiscrepancyPayer,
      return {
         intakeProgram: {
            heading: (
               <>
                  {`We're reviewing your insurance details`}
                  <HeadingIcon
                     name="insurance-error"
                     color={getIconColor('insurance-error')}
                  />
               </>
            ),
            text: (
               <>
                  {`We weren’t able to verify your insurance automatically, so
                  we’ve sent your information to our team for manual review.
                  This typically takes 1 business day. We’ll follow up as soon
                  as we have an update.`}
               </>
            ),
            buttons: ({ onSwitchClick }) => (
               <>
                  <Card.ButtonLink href="/support">
                     Contact Member Support
                  </Card.ButtonLink>
                  <Card.Button variant="outline" onClick={onSwitchClick}>
                     Continue without insurance
                  </Card.Button>
               </>
            ),
         },
         otherProgram: {
            heading: (
               <>
                  {`We're reviewing your insurance details`}
                  <HeadingIcon
                     name="insurance-error"
                     color={getIconColor('insurance-error')}
                  />
               </>
            ),
            text: (
               <>
                  {`We weren’t able to verify your insurance automatically, so
                  we’ve sent your information to our team for manual review.
                  This typically takes 1 business day. We’ll follow up as soon
                  as we have an update.`}
               </>
            ),
            buttons: () => (
               <Card.ButtonLink href="/support">
                  Contact Member Support
               </Card.ButtonLink>
            ),
         },
      };
   }

   return null;
};
