import { Flex, type FlexProps } from '@chakra-ui/react';
import { Badge } from './components/Badge';
import { Title } from './components/Title';
import { Text } from './components/Text';
import { ButtonGroup } from './components/ButtonGroup';
import { ButtonLink } from './components/ButtonLink';
import { Button } from './components/Button';
import { Image } from './components/Image';
import { InfoText } from './components/InfoText';

export const Card = ({ children, ...flexProps }: FlexProps) => {
   return (
      <Flex
         borderRadius={12}
         bg="linear-gradient(216.83deg, rgba(255, 156, 75, 0.12) 30.52%, rgba(255, 156, 75, 0) 91.09%), #FFFFFF;"
         overflow="hidden"
         boxShadow="0px 5px 5px rgba(0, 0, 0, 0.15)"
         position="relative"
         pr={{ base: 5, lg: 0 }}
         backgroundPosition="center"
         backgroundSize="cover"
         {...flexProps}
      >
         <Flex
            direction="column"
            alignItems="flex-start"
            px={{ base: 5, lg: 10 }}
            pt={{ base: 4, lg: 6 }}
            pb={{ base: 5, lg: 8 }}
            w="full"
            maxW={flexProps.maxW ?? '650px'}
            minW={{ base: '55%', lg: 'auto' }}
            zIndex={3}
         >
            {children}
         </Flex>
      </Flex>
   );
};

Card.Badge = Badge;
Card.Title = Title;
Card.Text = Text;
Card.ButtonGroup = ButtonGroup;
Card.ButtonLink = ButtonLink;
Card.Button = Button;
Card.Image = Image;
Card.InfoText = InfoText;
