import useMyPatient from './react-query/useMyPatient';
import { InsuranceInfoStatus } from '@innerwell/dtos';

export const useAppointmentCanBeScheduled = () => {
   const { data: patient } = useMyPatient();

   if (!patient) {
      return {
         canSchedule: false,
         error: null,
      };
   }

   if (
      !patient.insurance ||
      patient.insurance?.status === InsuranceInfoStatus.Eligible
   ) {
      return {
         canSchedule: true,
         error: null,
      };
   }

   if (
      patient.insurance.status === InsuranceInfoStatus.Pending ||
      patient.insurance.status === InsuranceInfoStatus.PendingError
   ) {
      return {
         canSchedule: false,
         error: 'We are checking your insurance',
      };
   }

   return {
      canSchedule: false,
      error: 'Please update your insurance info',
   };
};
